<style scoped>
	.lbti {
		padding-bottom: 20px;
		font-size: 1.2rem;
	}
	.page_box_header {
		padding-bottom: 20px;
	}
	div, input, textarea {
		box-sizing: border-box;
	}
	.page_box_add_community {
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	.page_box_body {
		overflow: auto;
		flex: 1;
	}
	.page_box_footer {
		padding: 10px 10px 0 10px;
		text-align: right;
	}
	/* 表单 */
	.z_form_item_row {
		padding: 10px;
	}
	.z_form_item {
		flex: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.z_form_item_label {
		width: 100px;
		text-align: right;
		padding-right: 10px;
	}
	.z_form_item_value {
		flex: 1;
	}
	.z_form input {
		height: 30px;
		width: 100%;
	}
	.z_form textarea {
		height: 80px;
		width: 100%;
		padding: 5px;
	}
</style>
<style>
	.z_form .el-form-item {
		margin-bottom: 0;
	}
	.z_form .el-form-item__content {
		margin-left: 0 !important;
	}
</style>
<style>
	.up_file_box .el-upload-dragger {
		width: 100%;
	}
	.up_file_box .el-upload.el-upload--text {
		width: 100%;
	}
</style>
<template>
	<div class="page_box_add_community">
		<div class="page_box_header">
			<div class="lbti">{{dialogTitle}}</div>
		</div>
		<div class="page_box_body">
			<div class="z_form">
				<div class="up_file_box">
					<el-upload class="upload-demo" drag action="/wy/owner/upload" ref="upload"
						:http-request="httpRequest" :multiple="false" :auto-upload="false" :on-change="selectFile"
						:limit="1">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将 xlsx 格式文件拖到此处，或<em>点击上传</em></div>
						<!-- <div class="el-upload__tip" slot="tip">上传xlsx 文件</div> -->
					</el-upload>
				</div>
			</div>
		</div>
		<div class="page_box_footer">
			<el-button @click="closeDialog()">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="onSubmit">立即上传</el-button>
		</div>

	</div>
</template>

<script>
	var _this;
	export default {
		props: ['pageParams'],
		data() {
			return {
				dialogTitle: '',
				form: {
					name: '',
					phone: '',
					id_card: '',
					owner_type: ''
				},
				rules: null,
				btnSaveLoading: false,
				fileList: []
			}
		},
		created: function() {
			_this = this;
			if (_this.pageParams.type == 1) {
				_this.dialogTitle = _this.pageParams.community.name + '     历史欠费导入'
			} else if (_this.pageParams.type == 2) {
				_this.dialogTitle = _this.pageParams.community.name + '     已缴费导入'
			}
		},
		methods: {
			selectFile(file, fileLists) {
				_this.fileList.push(file);
			},

			// _  ***** 自定义上传文件
			httpRequest(e) {
				let apiurl = '/wy/data/imports/dataLoadHis';
				if (_this.pageParams.type == 2) {
					apiurl = '/wy/data/imports/uploadOkPay';
				}
				_this.btnSaveLoading = true;
				var fileObject = e.file;
				var formData = new FormData();
				formData.append("file[]", fileObject);
				_this._axios({
					method: 'post',
					url: apiurl,
					headers: {
						"token": localStorage.getItem('token'),
						'Content-Type': 'multipart/form-data'
					},
					params: {
						residence_id: _this.pageParams.community.id,
					},
					data: formData,
				}).then((response) => {
					var res = response.data;
					if (res.code == 1) {
						_this.$emit('childrenEvent', {
							type: 1
						});
						_this.$emit('childrenEvent', {
							type: 'reload'
						});
						_this.$message({
							message: '导入成功！',
							type: 'success',
							duration: 1000,
							onClose: function() {}
						});
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch(() => {

				});
			},
			// _ *** 关闭弹窗
			closeDialog() {
				_this.$emit('childrenEvent', {
					type: _this.pageParams.type
				});
			},
			// _** 提交保存
			onSubmit() {
				this.$refs.upload.submit();
			},

		}
	}
</script>
