<style scoped="202104281048">
	.complete-box {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}
	.body-box {
		flex: 1;
		padding-top: 30px;
	}
	.row-info {
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #ccc;
		padding: 5px 0;
		align-items: center;
	}
	.row-info .row-info-title:nth-child(1), .row-info .row-info-title:nth-child(3) {
		width: 15rem;
		text-align: right;
	}
	.row-info-title {
		width: 8rem;
		padding-left: 10px;
	}
	.function-item {
		display: flex;
		flex-direction: row;
		padding: 15px 0;
		align-items: center;
	}
	.function-item .function-item-label:nth-child(1) {
		width: 10rem;
		text-align: right;
	}
	.function-item-label {
		width: 8rem;
		padding-left: 10px;
	}
	.updatere {
		width: 95%;
		margin: auto;
	}
	.table-title {
		font-size: 1.2rem;
		font-weight: bold;
	}
	.pagination-box {
		padding: 10px 0;
	}
</style>

<template>
	<div class="complete-box" v-loading="loading">
		<div class="header-box" v-if="hisTotleData.his_total">
			<div class="row-info">
				<div class="row-info-title">已导入历史欠费/元:</div>
				<div class="row-info-title">{{hisTotleData.his_total.qf_total}}</div>
				<div class="row-info-title">历史违约金合计/元:</div>
				<div class="row-info-title">{{hisTotleData.his_total.wy_total}}</div>
			</div>
			<div class="row-info" v-for="item in hisTotleData.his_total.item_total">
				<div class="row-info-title">{{item.item_name}}/元:</div>
				<div class="row-info-title">{{item.qf}}</div>
				<div class="row-info-title">违约金/元:</div>
				<div class="row-info-title">{{item.wy}}</div>
			</div>
		</div>
		<div class="body-box">
			<div class="function-item">
				<div class="function-item-label">历史欠费批量导入</div>
				<div class="function-item-label">
					<el-button type="warning" size="small" @click="download1()">下载模板</el-button>
				</div>
				<div class="function-item-label">
					<el-button type="success" size="small" @click="fileUpdateOpen(1)">导入模板</el-button>
				</div>
			</div>
			<div class="function-item">
				<div class="function-item-label">已收费批量导入</div>
				<div class="function-item-label">
					<el-button type="warning" size="small" @click="download2()">下载模板</el-button>
				</div>
				<div class="function-item-label">
					<el-button type="success" size="small" @click="fileUpdateOpen(2)">导入模板</el-button>
				</div>
			</div>

			<div class="updatere">
				<div class="table-title">
					<span>导入记录</span>
					<span style="font-size: 0.5rem;padding-left: 5px;">批量导入收费数据不可删除，如导入错误，请重新计算相关费用，另行导入正确数据</span>
				</div>
				<el-table :data="updateHistoryData" style="width: 100%">
					<el-table-column prop="create_time" label="日期" width="180"></el-table-column>
					<el-table-column prop="type_name" label="类型" width="180"></el-table-column>
					<el-table-column prop="op_account_name" label="操作人"></el-table-column>
					<el-table-column prop="address" label="操作">
						<template slot-scope="scope">
							<el-button type="danger" size="mini" v-if="scope.row.can_delete" @click="deleteR(scope.row)">
								删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-box">
					<el-pagination @current-change="handleCurrentChange" :current-page="updateHistoryPage.page_num"
						:page-sizes="[20]" :page-size="20" layout="total, sizes, prev, pager, next, jumper"
						:total="updateHistoryPage.total">
					</el-pagination>
				</div>
			</div>
		</div>

		<!-- 批量模板 -->
		<el-dialog custom-class="my_dialog ml" width="30%" top="none" title="" :visible.sync="dialogConfig.fileUpdate"
			destroy-on-close>
			<FileUpdate v-if="dialogConfig.fileUpdate" :pageParams="dialogConfig.fileUpdateParam"
				v-on:childrenEvent="fileUpdateEvent"></FileUpdate>
		</el-dialog>
	</div>
</template>

<script>
	import FileUpdate from '../fileUpdate/fileUpdate.vue';
	var _this;
	export default {
		props: ['subPageParams'],
		components: {
			FileUpdate: FileUpdate
		},
		data() {
			return {
				loading: true,
				dialogConfig: {
					fileUpdate: false,
					fileUpdateParam: null
				},
				hisTotleData: [],
				updateHistoryData: [],
				updateHistoryPage: {
					total: 0,
					page_num: 1,
					page_size: 20
				}
			}
		},
		created: function() {
			_this = this;
			_this.apiGetData();
			_this.apiGetUpdateJ();
		},
		mounted: function() {

		},
		methods: {
			/**
			 * @discribe 导入模板
			 * @author:郑凯
			 * @create_time:2021-4-28 16:04:39
			 * 1:历史欠费批量导入; 2.已收费批量导入
			 */
			fileUpdateOpen(type) {
				_this.dialogConfig.fileUpdateParam = {
					type: type,
					community: _this.subPageParams
				}
				_this.dialogConfig.fileUpdate = true;
			},
			fileUpdateEvent(e) {
				if(e.type == 'reload'){
					_this.apiGetData();
					_this.apiGetUpdateJ();
				}
				_this.dialogConfig.fileUpdate = false;
			},

			/**
			 * @discribe 下载模板
			 * @author:郑凯
			 * @create_time:2021-4-28 16:20:50
			 */
			download1() {
				_this._axios({
					method: 'get',
					url: '/wy/data/imports/historyArrearsTemplate',
					headers: {
						"token": localStorage.getItem('token')
					},
					responseType: 'arraybuffer'
				}).then((response) => {
					// console.log(response.getResponseHeaders('content-type'));
					//console.log(response)
					let blob = new Blob([response.data], {
						// type: 'application/vnd.ms-excel;charset-UTF-8'
						type: 'application/zip'
					})
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob); //创建下载的链接
					downloadElement.href = href;
					downloadElement.download = 'XXX小区历史欠费上传模板.zip'; //下载后文件名
					document.body.appendChild(downloadElement);
					downloadElement.click(); //点击下载
					document.body.removeChild(downloadElement); //下载完成移除元素
					window.URL.revokeObjectURL(href); //释放掉blob对象
				}).catch(() => {

				});
			},

			/**
			 * @discribe 下载模板
			 * @author:郑凯
			 * @create_time:2021-4-28 16:20:50
			 */
			download2() {
				_this._axios({
					method: 'get',
					url: '/wy/data/imports/payTemplate',
					headers: {
						"token": localStorage.getItem('token')
					},
					responseType: 'arraybuffer'
				}).then((response) => {
					// console.log(response.getResponseHeaders('content-type'));
					//console.log(response)
					let blob = new Blob([response.data], {
						// type: 'application/vnd.ms-excel;charset-UTF-8'
						type: 'application/zip'
					})
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob); //创建下载的链接
					downloadElement.href = href;
					downloadElement.download = 'XXX小区已收费批量导入模板.zip'; //下载后文件名
					document.body.appendChild(downloadElement);
					downloadElement.click(); //点击下载
					document.body.removeChild(downloadElement); //下载完成移除元素
					window.URL.revokeObjectURL(href); //释放掉blob对象
				}).catch(() => {

				});
			},

			/**
			 * @discribe 获取历史欠费信息
			 * @author:郑凯
			 * @create_time:2021-4-28 16:44:39
			 */
			apiGetData() {
				_this.loading = true;
				var params = {};
				params.residence_id = _this.subPageParams.id;
				_this._postApi('/wy/data/imports/costQFTotal', params)
					.then(res => {
						if (res.code == 1) {
							_this.hisTotleData = res.data;
						} else {
							_this
								.$confirm(res.msg, '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								})
								.then(() => {})
								.catch(() => {});
						}
						_this.loading = false;
					})
					.catch(err => {
						console.log(err);
					});
			},

			/**
			 * @discribe 获取导入记录
			 * @author:郑凯
			 * @create_time:2021-4-28 17:08:37
			 */
			apiGetUpdateJ() {
				_this._postApi('/wy/data/imports/recordList', {
						page: _this.updateHistoryPage.page_num,
						limit: _this.updateHistoryPage.page_size,
						residence_id: _this.subPageParams.id
					}).then(res => {
						if (res.code == 1) {
							_this.updateHistoryPage.total = res.data.count;
							_this.updateHistoryData = res.data.list;
						} else {
							_this
								.$confirm(res.msg, '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								})
								.then(() => {})
								.catch(() => {});
						}
						_this.loading = false;
					})
					.catch(err => {
						console.log(err);
					});
			},

			/**
			 * @discribe 切换分页
			 * @author:郑凯
			 * @create_time:2021-4-28 17:18:27
			 */
			handleCurrentChange(val) {
				_this.updateHistoryPage.page_num = val;
				_this.apiGetUpdateJ()
			},

			/**
			 * @discribe 删除
			 * @author:郑凯
			 * @create_time:2021-4-29 10:35:35
			 */
			deleteR(data) {
				_this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this._postApi('/wy/data/imports/qfRecordDelete', {
							rid: data.id
						}).then(res => {
							if (res.code == 1) {
								_this.apiGetUpdateJ();
								_this.$message({
									type: 'success',
									message: '删除成功!'
								});
							} else {
								_this.$confirm(res.msg, '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									})
									.then(() => {})
									.catch(() => {});
							}
						}).catch(err => {
							console.log(err);
						});
				}).catch(() => {
					_this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		}
	}
</script>
